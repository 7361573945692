.card-color {
  background-color: #cf34eb;
}

.mg-top {
  margin-top: 5%;
}

.header {
  background-color: #AB47BC;
  color: white;
}

.fs-3 {
  font-family: 'Courier New', Courier, monospace
}

.fs-1 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.text-area:active {
  border-color: black;
}

.modal-dialog {
  overflow-y: initial !important
}

.modal-body-content {
  height: 35vh;
  overflow-y: auto;
}

.fixed-height {
  min-height: 35vh;
}

@media screen and (max-width: 600px) {
  .modal-dialog {
    max-width: 100vw;
  }
}

@media screen and (min-width: 601px) {
  .modal-dialog {
    max-width: 45vw;
  }
}